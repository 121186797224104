<template>
  <div>
    <ValidationObserver ref="observer" v-if="Object.keys(this.dealer).length != 0 || !this.is_editing">
      <v-card>
        <v-card-title>{{$route.meta.title}}</v-card-title>
        <v-card-subtitle>
            <div class="red--text" v-if="!is_editing && error_message != null && error_message.length > 0">
                {{error_message}}
            </div>
            <div class="red--text" v-if="Object.keys(dealer).length === 0 && loading_complete && is_editing">
                Error: Dealer with id {{dealer_id}} cannot be found.
            </div>
            <div class="green--text" v-if="patch_success">
                {{$route.meta.success_text}}
            </div>
        </v-card-subtitle>
        <v-container>
          <v-row>
            <v-col
              lg="4"
              md="6"
              sm="12"
            >
              <ValidationProvider v-slot="{ errors }" name="Dealer name" rules="required">
                <v-text-field
                  outlined
                  v-model="dealer.name"
                  :counter="50"
                  :error-messages="errors"
                  label="Dealer Name">
                </v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col
              lg="4"
              md="6"
              sm="12"
            >
              <ValidationProvider v-slot="{ errors }" name="Dealer Group" rules="required">
                <v-select
                outlined
                item-text="name"
                item-value="id"
                label="Dealer Group"
                v-model="dealer.dealerGroupId"
                :items="dealer_groups"
                :error-messages="errors">
                </v-select>
              </ValidationProvider>
            </v-col>
            <v-col
              lg="4"
              md="6"
              sm="12"
            >
              <ValidationProvider v-slot="{ errors }" name="Make" rules="required">
                <v-select
                  outlined
                  item-text="name"
                  item-value="name"
                  v-model="dealer.make"
                  :items="makes"
                  label="Make"
                  deletable-chips
                  chips
                  persistent-hint
                  return-object
                  :error-messages="errors">
                </v-select>
              </ValidationProvider>

            </v-col>
            <v-col
              lg="4"
              md="6"
              sm="12"
            >
              <ValidationProvider v-slot="{ errors }" name="Order Email Contacts">
                <v-combobox
                  outlined
                  :append-icon="null"
                  v-model="dealer.orderEmails"
                  label="Order Email Contacts"
                  :delimiters ="[' ',',']"
                  multiple
                  deletable-chips
                  chips
                  persistent-hint
                  :error-messages="errors"
                >
                </v-combobox>
              </ValidationProvider>
            </v-col>
            <v-col
              lg="4"
              md="6"
              sm="12"
            >
              <ValidationProvider v-slot="{ errors }" name="Code" rules="required">
                <v-text-field
                  outlined
                  v-model="dealer.code"
                  :counter="20"
                  label="Code"
                  required
                  :error-messages="errors">
                </v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col
              lg="4"
              md="6"
              sm="12"
            >
              <ValidationProvider v-slot="{ errors }" name="Address" rules="required">
                <v-text-field
                    outlined
                    v-model="dealer.address"
                    :counter="300"
                    label="Address"
                    required
                    :error-messages="errors">
                </v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col
              lg="4"
              md="6"
              sm="12"
            >
              <ValidationProvider v-slot="{ errors }" name="City" rules="required">
                <v-text-field
                    outlined
                    v-model="dealer.city"
                    :counter="50"
                    label="City"
                    required
                    :error-messages="errors">
                </v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col
              lg="4"
              md="6"
              sm="12"
            >
              <ValidationProvider v-slot="{ errors }" name="Province" rules="required">
                <v-text-field
                  outlined
                  v-model="dealer.province"
                  :counter="20"
                  label="Province"
                  required
                  :error-messages="errors">
                </v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col
              lg="4"
              md="6"
              sm="12"
            >
              <ValidationProvider v-slot="{ errors }" name="Postal Code" rules="required">
                <v-text-field
                  outlined
                  v-model="dealer.postalCode"
                  :counter="10"
                  label="Postal Code"
                  required
                  :error-messages="errors">
                </v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col
              lg="4"
              md="6"
              sm="12"
            >
              <ValidationProvider v-slot="{ errors }" name="Country" rules="required">
                <v-text-field
                  outlined
                  v-model="dealer.country"
                  :counter="50"
                  label="Country"
                  required
                  :error-messages="errors">
                </v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col
              lg="4"
              md="6"
              sm="12"
            >
              <ValidationProvider v-slot="{ errors }" name="Phone Number" rules="required">
                <v-text-field
                  outlined
                  v-model="dealer.phone"
                  :counter="20"
                  label="Phone Number"
                  required
                  :error-messages="errors">
                </v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col
              lg="4"
              md="6"
              sm="12"
            >
            <ValidationProvider v-slot="{ errors }" name="Email Address">
              <v-text-field
                outlined
                v-model="dealer.email"
                :counter="100"
                label="Email Address"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
            </v-col>
            <v-col
              lg="4"
              md="6"
              sm="12"
            >
              <ValidationProvider v-slot="{ errors }" name="Facebook">
                <v-text-field
                  outlined
                  v-model="dealer.facebook"
                  :counter="100"
                  label="Facebook"
                  :error-messages="errors">
                </v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col
              lg="4"
              md="6"
              sm="12"
            >
              <ValidationProvider v-slot="{ errors }" name="Twitter">
                <v-text-field
                  outlined
                  v-model="dealer.twitter"
                  :counter="100"
                  label="Twitter"
                  :error-messages="errors">
                </v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col
              lg="4"
              md="6"
              sm="12"
            >
              <ValidationProvider v-slot="{ errors }" name="Youtube">
                <v-text-field
                  outlined
                  v-model="dealer.youtube"
                  :counter="100"
                  label="Youtube"
                  :error-messages="errors">
                </v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col
              lg="4"
              md="6"
              sm="12"
            >
              <ValidationProvider v-slot="{ errors }" name="Website">
                <v-text-field
                  outlined
                  v-model="dealer.website"
                  :counter="100"
                  label="Website"
                  :error-messages="errors">
                </v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col
              lg="4"
              md="6"
              sm="12"
            >
              <ValidationProvider v-slot="{ errors }" name="GST">
                <v-text-field
                  outlined
                  v-model="dealer.gst"
                  :counter="100"
                  label="GST"
                  :error-messages="errors">
                </v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col
              lg="4"
              md="6"
              sm="12"
            >
              <ValidationProvider v-slot="{ errors }" name="PST">
                <v-text-field
                  outlined
                  v-model="dealer.pst"
                  :counter="100"
                  label="PST"
                  :error-messages="errors">
                </v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col
              lg="4"
              md="6"
              sm="12"
            >
              <ValidationProvider v-slot="{ errors }" name="HST">
                <v-text-field
                  outlined
                  v-model="dealer.hst"
                  :counter="100"
                  label="HST"
                  :error-messages="errors">
                </v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col
              lg="4"
              md="6"
              sm="12"
            >
              <ValidationProvider v-slot="{ errors }" name="Hourly Rate">
                <v-text-field
                  outlined
                  v-model="dealer.hourlyRate"
                  :counter="100"
                  label="Hourly Rate"
                  :error-messages="errors">
                </v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              lg="4"
              md="6"
              sm="12"
            >
              <ValidationProvider v-slot="{ errors }" name="Only Install Price">
                <v-checkbox v-model="dealer.onlyInstallPrice" label="Only Install Price" :error-messages="errors"></v-checkbox>
              </ValidationProvider>
            </v-col>
            <v-col
              lg="4"
              md="6"
              sm="12"
            >
              <ValidationProvider v-slot="{ errors }" name="Initial Setup Done">
                <v-checkbox v-model="dealer.initialSetupDone" label="Initial Setup Done" :error-messages="errors"></v-checkbox>
              </ValidationProvider>
            </v-col>
            <v-col
              lg="4"
              md="6"
              sm="12"
            >
              <ValidationProvider v-slot="{ errors }" name="Active">
                <v-checkbox
                  v-model="dealer.active"
                  label="Active"
                  :error-messages="errors"
                ></v-checkbox>
              </ValidationProvider>
            </v-col>
            <v-col
            lg="12"
            md="12"
            sm="12">
              <ValidationProvider v-slot="{ errors }" name="Logo">
                <v-file-input
                  outlined
                  v-model="uploadedImages"
                  label="Logo"
                  :error-messages="errors"
                  @change="onFileChange"
                ></v-file-input>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row v-if="this.dealer.dealerLogo">
            <v-col lg="12" md="12" sm="12">
              <v-card>
                <v-card-title>Logo</v-card-title>
                <v-card-text>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-img
                      contain
                      max-width="150px"
                      max-height="100px"
                      width="150px"
                      height="100px"
                      class="image_preview align-self-center"
                      :src="cloudinaryUrl(this.dealer.dealerLogo.photo)"
                    />
                    <div class="flex-grow-1">
                      <v-card-subtitle class="file-name">
                        {{ this.dealer.dealerLogo.photo }}
                      </v-card-subtitle>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <v-btn
          fab
          bottom
          right
          fixed
          @click="onSubmit"
          color="success"
          class="mr-4"
        >
          <v-icon>
            mdi-content-save
          </v-icon>
        </v-btn>
      </v-card>
    </ValidationObserver>
    <v-snackbar
      v-model="snackbar"
      top
      right
    >
      {{ snackbar_text }}
      <v-btn
        color="blue"
        text
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </div>

</template>

<script>
import { NetworkService } from '../../services/NetworkService'

export default {
  props: {
    source: String
  },
  computed: {
    cols () {
      const { lg, sm } = this.$vuetify.breakpoint
      return lg ? 4 : sm ? 12 : 6
    }
  },
  data () {
    return {
      dealer: {
        image: null
      },
      dealer_id: this.$route.params.dealer_id,
      dealer_groups: [],
      uploadedImages: [],
      deletedImages: [],
      makes: [],
      loading_complete: false,
      patch_success: false,
      is_editing: this.$route.meta.is_editing,
      error_message: '',
      snackbar: false,
      snackbar_text: ''
    }
  },
  beforeMount: function () {
    this.initialSetup()
  },
  methods: {
    initialSetup () {
      if (this.is_editing) {
        this.getDealer()
      } else {
        this.loading_complete = true
      }

      NetworkService.get(process.env.VUE_APP_API_BASE_URL + '/api/v1/auth/dealer_groups').then(response => {
        this.dealer_groups = response.data
      }).catch(error => console.log(error))

      NetworkService.get(process.env.VUE_APP_API_BASE_URL + '/api/v1/auth/makes').then(response => {
        this.makes = response.data
      }).catch(error => console.log(error))
    },
    deleteLogo () {
      this.dealer.logo = null
    },
    getDealer () {
      this.dealer = this.$store.state.dealers.currentDealer
      this.loading_complete = true
    },
    onSubmit () {
      this.$refs.observer.validate().then((valid) => {
        if (valid) {
          this.sendHttpRequest()
        } else {
          this.snackbar_text = 'One or more of the fields were invalid. Please try again.'
          this.snackbar = true
        }
      })
    },
    sendHttpRequest () {
      if (this.is_editing) {
        this.patchDealer()
      } else {
        this.createDealer()
      }
    },
    patchDealer () {
      this.dealer.make = this.dealer.make.id
      NetworkService.patch(process.env.VUE_APP_API_BASE_URL + '/api/v1/auth/dealers/' + this.dealer.id, {
        ...this.prepareRequestJson()
      }).then(res => {
        this.patch_success = true
        this.snackbar_text = 'Dealer saved successfully.'
        this.snackbar = true
        this.$router.replace('/dealers')
      }).catch(err => console.log(err.response.data))
    },
    createDealer () {
      NetworkService.post(process.env.VUE_APP_API_BASE_URL + '/api/v1/auth/dealers/', {
        ...this.prepareRequestJson()
      }).then(res => {
        this.patch_success = true
        this.snackbar_text = 'Dealer created successfully.'
        this.snackbar = true
        this.$router.replace('/dealers')
      }).catch(err => {
        if (err.response.data.message != null) {
          this.error_message = err.response.data.message
        }
      })
    },
    prepareRequestJson () {
      const request = {
        ...this.dealer
      }
      return request
    },
    onFileChange (file) {
      (function (file, vm) {
        var reader = new FileReader()
        const byte_array = []
        reader.onload = function (e) {
          vm.dealer.image = ({
            id: file.name,
            name: file.name,
            photo: e.target.result,
            new: true
          })
        }
        reader.readAsDataURL(file)
      })(file, this)
    }
  }
}
</script>
