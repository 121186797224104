import { NetworkService } from '../services/NetworkService'

interface IModel {
   id: string
   name?: string
   yearId?: number
   makeId?: string
   active? : boolean
}

export class Model implements IModel {
   id!: string
   name!: string
   makeId!: string
   yearId!: number
   active!: boolean

   constructor (params: IModel) {
     Object.assign(this, params)
   }

   public static async getModels(makeId: string): Promise<Model[]> {
     const models : Model[] = []

     const res = await NetworkService.get(process.env.VUE_APP_API_BASE_URL + '/api/v1/auth/models?makeId=' + makeId)
     res.data.data.forEach((m : Model) => {
       const model : Model = new Model(m)
       models.push(model)
     })

     return Promise.resolve(models)
   }

   public static async changeStatus(modelId: string) {
    const res = await NetworkService.post(
      process.env.VUE_APP_API_BASE_URL +
      '/api/v1/auth/models/change_status/' + modelId
    )
  
    return Promise.resolve(res.status)
  }
}
