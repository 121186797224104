import { NetworkService } from '../services/NetworkService'

interface IMake {
   id: string
   name?: string
   active? : boolean
}

export class Make implements IMake {
   id!: string
   name!: string
   active!: boolean

   constructor (params: IMake) {
     Object.assign(this, params)
   }

   public static async getMakes (): Promise<any> {
     const makes : Make[] = []

     await NetworkService.get(
       process.env.VUE_APP_API_BASE_URL +
      '/api/v1/auth/makes'
     ).then(response => {
       response.data.forEach((t : any) => {
         const make : Make = new Make(t)
         makes.push(make)
       })
     })
     return makes
   }

   public static async getMake (makeId: string): Promise<Make | null> {
     const res = await NetworkService.get(
       process.env.VUE_APP_API_BASE_URL +
        '/api/v1/auth/makes/' + makeId
     )
     return Promise.resolve(new Make(res.data))
   }
}
