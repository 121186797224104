import Vue from 'vue'
import App from './App.vue'
import Vuetify from './plugins/vuetify.js'
import { ValidationProvider, ValidationObserver, extend, setInteractionMode } from 'vee-validate'
import { required, max, email, alpha_spaces, numeric, length, alpha_num, alpha, alpha_dash, regex } from 'vee-validate/dist/rules'
import router from './router'
import store from './store'
import draggable from 'vuedraggable'
import Editor from '@tinymce/tinymce-vue'

import axios from 'axios'

Vue.config.productionTip = false
axios.defaults.withCredentials = true

setInteractionMode('eager')

extend('max', {
  ...max,
  message: '{_field_} may not be greater than {length} characters'
})

extend('required', {
  ...required,
  message: 'This field is required'
})

extend('email', {
  ...email,
  message: 'Please enter a valid e-mail'
})

extend('alpha', {
  ...alpha,
  message: 'The contents of this field must be alphabetical.'
})

extend('alpha_num', {
  ...alpha_num,
  message: 'The contents of this field must be alpha-numeric.'
})

extend('alpha_spaces', {
  ...alpha_spaces,
  message: 'This field can only contain alphabetical characters and spaces.'
})

extend('alpha_dash', {
  ...alpha_dash,
  message: 'This field can only contain alphabetical characters, numbers, dashes, or underscores.'
})

extend('length', {
  ...length,
  message: 'Please make sure your number is 10 digits (include area code)'
})

extend('numeric', {
  ...numeric,
  message: 'Please enter a valid number (numbers only, no special characters)'
})

extend('regex', {
  ...regex,
  message: 'The input must be a decimal number'
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('draggable', draggable)
Vue.component('editor', Editor)

export const bus = new Vue()

Vue.mixin({
  methods: {
    cloudinaryUrl: function(publicId: string, opts: string = "", make: string) {
      return (
          "https://res.cloudinary.com/shopaccessories/image/upload/" + opts + make + "/products/" + publicId
      ) 
    },
  }
})

store.dispatch('auth/me').then(() => {
  new Vue({
    vuetify: Vuetify,
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})
