import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../components/Home.vue'
import NotFound from '../components/NotFound.vue'
import Dealers from '../components/Dealers/Dealers.vue'
import Dealer from '../components/Dealers/Dealer.vue'
import Login from '../components/Login.vue'
import Makes from '../components/Makes/Makes.vue'
import Make from '../components/Makes/Make.vue'
import CreateProduct from '../components/Products/CreateProduct.vue'
import EditProduct from '../components/Products/EditProduct.vue'

import store from '@/store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'root',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/dealers/:dealer_id/edit',
    name: 'dealer',
    component: Dealer,
    meta: {
      requiresAuth: true,
      title: 'Edit Dealer',
      success_text: 'Dealer has been successfully updated',
      is_editing: true
    }
  },
  {
    path: '/dealers/create',
    name: 'dealer_create',
    component: Dealer,
    meta: {
      requiresAuth: true,
      title: 'Create New Dealer',
      success_text: 'Dealer has been successfully added',
      is_editing: false
    }
  },
  {
    path: '/dealers',
    name: 'dealers',
    component: Dealers,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/makes/:make_id',
    name: 'make',
    component: Make,
    meta: {
      requiresAuth: true,
      is_editing: true
    }
  },
  {
    path: '/makes',
    name: 'makes',
    component: Makes,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/products/:product_id/edit',
    name: 'product',
    component: EditProduct,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/products/create',
    name: 'product_create',
    component: CreateProduct,
    meta: {
      requiresAuth: true,
      title: 'Add Product'
    }
  },
  {
    path: '*',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const isAuth = store.getters['auth/isAuth']
    if (!isAuth) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else { next() }
})

export default router
