




































































































































































































































































































































































import Vue from 'vue'
import { mapGetters } from 'vuex'

import { NetworkService } from '../../services/NetworkService'

import { Product } from '../../models/Product'
import { Trim } from '../../models/Trim'

export default Vue.extend({

  data() {
    return {
      categories: [{'id': null, 'name': 'None'}],
      snackbar: false,
      snackbarText: '',
      uploadedImages: null,
      deletedImages: [] as any[],
      trims: [] as Trim[],
      products: [] as Product[],
      productSearch: '' as string,
      options: {
        itemsPerPage: 50,
        page: 1,
        sortDesc: [],
        sortBy: []
      },
      product: {
        images: [],
        products: [],
        trims: []
      } as any,
      originalProduct: null
    }
  },
  computed: {
    ...mapGetters({
      make: 'makes/currentMake',
      selectedProduct: 'products/selectedProduct'
    }),
    title() : string {
      return this.selectedProduct.name 
    },
    obs () : any {
      return this.$refs.observer as any
    },
    tinymceKey() : string {
      return process.env.VUE_APP_TINYMCE_API_KEY
    },
    disabled() : boolean {
      if (this.product.categoryId) {
        return this.product.categoryId === 4 ? false : true
      } 
      return true
    },
    allTrims() : boolean {
      return this.product.trims.length === this.trims.length
    },
    someTrims() : boolean {
      return this.product.trims.length > 0 && !this.allTrims
    },
    icon() {
      if (this.allTrims) return 'mdi-close-box'
      if (this.someTrims) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }
  },
  methods: {
    async initialSetup() {
      await NetworkService.get(process.env.VUE_APP_API_BASE_URL + '/api/v1/auth/categories').then(response => {
        this.categories = this.categories.concat(response.data)
      }).catch(error => console.log(error))

      this.trims = await Trim.getTrimsByMake(this.make.id)
      this.product = JSON.parse(JSON.stringify(this.selectedProduct))
      const trim_ids : string[] = []
      this.product.trims.forEach((trim: Trim) => {
        trim_ids.push(trim.id)
      })
      this.product.trims = trim_ids
      if (this.product.categoryId === 4) {
        await this.attachProductsToPackage()
      }
    },
    async attachProductsToPackage() {
      for (const product of this.product.products) {
        const prod : any = await Product.getProduct(product.id)
        this.products.push(prod)
      }
    },
    trimFilter(trim: any, queryText: string) {
      const name = trim.name.toLowerCase()
      const year = trim.model.year.year.toString().toLowerCase()
      const model = trim.model.name.toLowerCase()
      const result = year.concat(' ' + model.concat(' ' + name))
      const searchText = queryText.toLowerCase()

      return result.indexOf(searchText) > -1
    },
    toggle() {
      this.$nextTick(() => {
        if (this.allTrims) {
          this.product.trims = []
        } else {
          const trimIds : string[] = []
          this.trims.forEach((trim: Trim) => {
            trimIds.push(trim.id)
          })
          this.product.trims = trimIds
        }
      })
    },
    async requestProducts() {
      const currentProducts = this.products
      const res = await Product.getProducts(this.make.id, this.options, this.productSearch)
      this.products = res.products
      currentProducts.forEach((prod : Product) => {
        this.products.push(prod)
      })
    },
    async updateProduct() {
      const isValid = await this.obs.validate()
      if (isValid) {
        this.patchProduct()
      } else {
        this.$vuetify.goTo(".error--text")
      }
    },
    patchProduct() {
      NetworkService.patch(process.env.VUE_APP_API_BASE_URL + '/api/v1/auth/products/' + this.product.id, {...this.prepareRequestJson()}).then(res => {
        if (res.data.product) {
          this.uploadedImages = []
          this.product.deletedImages = []
          this.snackbarText = 'Product saved successfully.'
          this.snackbar = true
        }
      }).catch(err => console.log(err.response.data))
    },
    prepareRequestJson() {
      let request = {
        ...this.product,
        deleted_images: this.deletedImages,
        make: this.make.name
      }
      return request
    },
    onFileChange(files : any) { 
      if (this.product.images) {
        for(let i = 0; i < this.product.images.length; i++) {
          if (this.product.images[i].new) {
            this.product.images.splice(i, 1)
            i--
          }
        }
      } else {
        this.product.images = []
      }

      for (let i = 0; i < files.length; i++) { //for multiple files          
        (function(file,vm) {
          var reader = new FileReader(); 
          reader.onload = function(e : any) {
            vm.product.images.push({
              id: file.name + '_' + i,
              name: file.name,
              photo: e.target.result,
              new: true
            }); 
          }
          reader.readAsDataURL(file)
        })(files[i],this)
      }
    },
    onImageDelete(item : any) {
      for(let i = 0; i < this.product.images.length; i++) {
        if (this.product.images[i].id == item.id) {
            this.product.images.splice(i, 1)
            if (!item.new) {
              this.deletedImages.push(item.id)
            }
            break
        }
      }
    },
    cancel() {
      this.product = this.originalProduct
      this.$router.go(-1)
    },
  },
  components: {
  },
  async mounted() {
    await this.initialSetup()
    this.originalProduct = this.selectedProduct
  },
})
