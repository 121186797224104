import { Trim } from '../../models/Trim'

const mutations = {
  addTrim (state: any, trim: Trim) {
    state.list.push(trim)
  },
  clearTrims (state: any) {
    state.list = []
  },
  currentTrim (state: any, trim: Trim) {
    state.currentTrim = trim
  }
}

const getters = {}

const actions = {
  async loadTrims (context: any, modelId: string) {
    const res = await Trim.getTrims(modelId)

    res.forEach((trim: Trim) => {
      context.commit('addTrim', trim)
    })
  },

  async reloadTrims (context: any, modelId: string) {
    context.commit('clearTrims')
    context.dispatch('loadTrims', modelId)
  }
}

const state = () => ({
  list: [] as Trim[],
  nextPageUrl: '' as string
})

export default {
  namespaced: true,
  mutations,
  getters,
  state,
  actions
}
