import { Model } from '../../models/Model'

const mutations = {
  ADD_MODEL (state: any, model: Model) {
    state.list.push(model)
  },
  CLEAR_MODELS (state: any) {
    state.list = []
  },
  SET_LOADING (state: any, loading: boolean) {
    state.loading = loading
  }
}

const getters = {
  models (state: any) {
    return state.list
  },
  loading (state: any) {
    return state.loading
  }
}

const actions = {
  async loadModels (context : any, makeId: string) {
    context.commit('SET_LOADING', true)
    const res = await Model.getModels(makeId)

    res.forEach((model: Model) => {
      context.commit('ADD_MODEL', model)
    })

    context.commit('SET_LOADING', false)
  },
  async reloadModels (context: any, args: any) {
    context.commit('CLEAR_MODELS')
    context.dispatch('loadModels', args.make)
  }
}

const state = () => ({
  list: [] as Model[],
  loading: false as boolean
})

export default {
  namespaced: true,
  mutations,
  getters,
  state,
  actions
}
