
































































































import Vue from 'vue'

import { mapGetters } from 'vuex'

import { Model } from '@/models/Model'
import { Trim } from '@/models/Trim'

export default Vue.extend({
  data () {
    return {
      search: '' as string,
      headers: [
        { text: 'Model Name', value: 'name' },
        { text: 'Active', value: 'active'},
        { text: '', value: 'actions' }
      ],
      trims: [] as Trim[],
      modelConfirmationDialog: false as boolean,
      loading: false as boolean,
      snackbarText: '',
      snackbar: false,
      dialogText: '' as string,
      dialogTitle: '' as string,
      dialogButtonText: '' as string,
      dialogButtonColour: '' as string,
      selectedModel: null as Model,
      selectedTrim: null as Trim
    }
  },
  computed: {
    ...mapGetters({
      make: 'makes/currentMake',
      models: 'models/models'
    }),
  },
  mounted() {
    this.getTrims()
  },
  methods: {
    async getTrims () {
      this.loading = true
      this.trims = await Trim.getTrimsByMake(this.make.id)
      this.loading = false
    },
    handleModelClick(item: Model) {
      this.selectedModel = item
      if(item.active == true) {
        this.dialogButtonColour = 'error'
        this.dialogText = 'Are you sure you want to deactivate this model?'
        this.dialogTitle = 'Deactivate Model'
        this.dialogButtonText = 'Deactivate'
      } else {
        this.dialogButtonColour = 'success'
        this.dialogText = 'Are you sure you want to activate this model?'
        this.dialogTitle = 'Activate Model'
        this.dialogButtonText = 'Activate'
      }
      this.modelConfirmationDialog = true
    },
    async changeModelStatus(item: Model) {
      this.loading = true
      const response = await Model.changeStatus(item.id)
      this.loading = false
      this.modelConfirmationDialog = false
      if (response === 200) {
        if (item.active == true) {
          this.snackbarText = 'The selected model has been successfully deactivated.'
        } else {
          this.snackbarText = 'The selected model has been successfully activated.'
        }
        this.getTrims()
      } else {
        if (item.active == true) {
          this.snackbarText = 'There was an error attempting to deactivate the model.'
        } else {
          this.snackbarText = 'There was an error attempting to activate the model.'
        }
      }
      this.snackbar = true
    },
  }
})
