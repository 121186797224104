import axios from 'axios'

export class NetworkService {
  public static async get (url : string) {
    let data: any
    data = await axios.get(url)
    return Promise.resolve(data)
  }

  public static async patch (url: string, object: any) {
    let data: any
    data = await axios.patch(url, object)
    return Promise.resolve(data)
  }

  public static async post (url: string, object: any = null) {
    let data: any
    data = await axios.post(url, object)
    return Promise.resolve(data)
  }

  public static async postCsv (url: string, object: any = null) {
    try {
      const res = await axios.post(url, 
        object,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })

      return Promise.resolve(res)
    } catch (error) {
      return Promise.resolve([-1])
    }
  }
}
