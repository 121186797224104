








import Vue from 'vue'

import SignInForm from '../components/SignInForm.vue'

export default Vue.extend({
  name: 'SignIn',
  data: () => {
    return {}
  },
  methods: {},
  components: {
    SignInForm
  }
})
