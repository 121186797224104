import { Dealer } from '../../models/Dealer'

const mutations = {
  ADD_DEALER (state: any, dealer: Dealer) {
    state.list.push(dealer)
  },
  CLEAR_DEALERS (state: any) {
    state.list = []
  },
  SET_CURRENT_DEALER (state: any, dealer: Dealer) {
    state.currentDealer = dealer
  },
  CLEAR_CURRENT_DEALER (state: any) {
    state.currentDealer = null
  },
  SET_LOADING (state: any, loading: boolean) {
    state.loading = loading
  }
}

const getters = {
  loading (state: any) {
    return state.loading
  }
}

const actions = {
  async loadDealers (context : any, args : any) {
    context.commit('SET_LOADING', true)
    const res = await Dealer.getDealers()
    res.forEach((dealer: Dealer) => {
      context.commit('ADD_DEALER', dealer)
    })
    context.commit('SET_LOADING', false)
  },
  async loadDealer (context : any, args: any) {
    context.commit('SET_LOADING', true)
    const dealer: Dealer | null = await Dealer.getDealer(args.code, args.makeId)
    if (dealer != null) {
      context.commit('SET_CURRENT_DEALER', dealer)
    }
    context.commit('SET_LOADING', false)
  },
  async reloadDealers (context: any, args: any) {
    context.commit('CLEAR_DEALERS')
    context.dispatch('loadDealers', args)
  }
}

const state = () => ({
  list: [] as Dealer[],
  currentDealer: Dealer,
  loading: false as boolean
})

export default {
  namespaced: true,
  mutations,
  getters,
  state,
  actions
}
