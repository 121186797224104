import { NetworkService } from '../../services/NetworkService'

const mutations = {
  setAuthenticated (state: any, value: boolean) {
    state.authenticated = value
  }
}

const getters = {
  isAuth (state: any) {
    return state.authenticated
  }
}

const actions = {
  async signIn (context: any, form: object) {
    try {
      const csrf_response = await NetworkService.get(process.env.VUE_APP_API_BASE_URL + '/sanctum/csrf-cookie')
      if (csrf_response.status === 204) {
        try {
          const response = await NetworkService.post(process.env.VUE_APP_API_BASE_URL + '/api/login/admin', form)
          if (response.status === 200) {
            context.commit('setAuthenticated', true)
            return response.status
          }
        } catch (error) {
          return -1
        }
      }
    } catch (error) {
      return -1
    }
  },
  async signOut (context: any) {
    try {
      const response = await NetworkService.post(process.env.VUE_APP_API_BASE_URL + '/api/logout')
      context.commit('setAuthenticated', false)
      return response.status
    } catch (error) {
      return -1
    }
  },

  async me (context: any) {
    try {
      const response = await NetworkService.get(process.env.VUE_APP_API_BASE_URL + '/api/v1/auth/user/checkAuth')
      context.commit('setAuthenticated', true)
    } catch (error) {
      context.commit('setAuthenticated', false)
    }
  }
}

const state = () => ({
  authenticated: false as boolean
})

export default {
  namespaced: true,
  mutations,
  getters,
  state,
  actions
}
