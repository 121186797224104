































































import Vue from 'vue'

export default Vue.extend({
  data() {
    return {
      dateRange: [] as string[],
      defaultRanges: [
        { title: 'Today' , startDate: null, endDate: null},
        { title: 'Last 7 Days', startDate: null, endDate: null },
        { title: 'Last 30 Days', startDate: null, endDate: null },
        { title: 'Last Month', startDate: null, endDate: null },
        { title: 'This Month', startDate: null, endDate: null },
        { title: 'Current Year', startDate: null, endDate: null },
        { title: 'Custom Range', startDate: null, endDate: null, custom: true}
      ],
      showCalendar: false as boolean,
      menu: false as boolean
    }
  },
  computed: {
    formattedRange: {
      get: function () : string {
        return this.dateRange[0] + ' - ' + this.dateRange[1]
      },

      set: function () : string {
        return 'Select A Range'
      }
    }
  },
  methods: {
    clear() {
      this.dateRange = []
      this.showCalendar = false
      this.emitNewRange()
    },
    emitNewRange() {
      this.$emit('updatedDateRange', [this.dateRange[0], this.dateRange[1]])
    },
    setInitialDates() : void {
      const today = new Date()
      const todayString = today.toISOString().substring(0,10)
      
      const sevenDaysAgo = new Date()
      sevenDaysAgo.setDate(today.getDate() - 7)
      const sevenDaysAgoString = sevenDaysAgo.toISOString().substr(0,10)
      
      const thirtyDaysAgo = new Date()
      thirtyDaysAgo.setDate(today.getDate() - 30)
      const thirtyDaysAgoString = thirtyDaysAgo.toISOString().substr(0,10)

      const lastMonth = new Date(today)
      lastMonth.setDate(0)
      const lastMonthEndString = lastMonth.toISOString().substr(0, 10)
      lastMonth.setDate(1)
      const lastMonthStartString = lastMonth.toISOString().substr(0, 10)

      const startOfMonthString = new Date(today.getFullYear(), today.getMonth(), 1).toISOString().substr(0,10)
      const endOfMonthString = new Date(today.getFullYear(), today.getMonth() + 1, 0).toISOString().substr(0,10)

      const startOfYearString = new Date(today.getFullYear(), 0, 1).toISOString().substr(0,10)
      const endOfYearString = new Date(today.getFullYear(), 11, 31).toISOString().substr(0,10)

      this.dateRange[0] = startOfMonthString
      this.dateRange[1] = endOfMonthString

      this.defaultRanges[0].startDate = todayString
      this.defaultRanges[0].endDate = todayString
      this.defaultRanges[1].startDate = sevenDaysAgoString
      this.defaultRanges[1].endDate = todayString
      this.defaultRanges[2].startDate = thirtyDaysAgoString
      this.defaultRanges[2].endDate = todayString
      this.defaultRanges[3].startDate = lastMonthStartString
      this.defaultRanges[3].endDate = lastMonthEndString
      this.defaultRanges[4].startDate = startOfMonthString
      this.defaultRanges[4].endDate = endOfMonthString
      this.defaultRanges[5].startDate = startOfYearString
      this.defaultRanges[5].endDate =endOfYearString

      this.$emit('updatedDateRange', [this.dateRange[0], this.dateRange[1]])
    },
    defaultSelected(item : any) {
      this.dateRange = [item.startDate, item.endDate]
      if (item.custom) this.showCalendar = true
      else if (!item.custom && !this.showCalendar) this.menu = false
      this.emitNewRange()
    } 
  },
  created() {
    this.setInitialDates()
  }
})
