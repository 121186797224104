



















import Vue from 'vue'

import { mapGetters } from 'vuex'

import { Make } from '@/models/Make'
 
export default Vue.extend({
  props: {
    source: String
  },
  data () {
    return {
      headers: [
        {
          text: 'Name',
          align: 'start',
          value: 'name'
        }
      ],
      makes: [] as Make[]
    }
  },
  computed: {
    ...mapGetters({
      loading: 'makes/loading'
    }),
  },
  mounted() {
    this.getAllMakes()
  },
  methods: {
    getAllMakes () {
      this.$store.dispatch('makes/reloadMakes')
      this.makes = this.$store.state.makes.list
    },
    async handleRowClick ($item : any) {
      await this.$store.dispatch('makes/loadMake', $item.id)
      //this.$store.dispatch('models/reloadModels', this.$store.state.makes.currentMake.id)
      this.$router.push('/makes/' + $item.id)
    }
  }
})
