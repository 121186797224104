import { NetworkService } from '../services/NetworkService'

import { Make } from '../models/Make'

interface IDealer {
  id: number
  dealerGroupId?: number
  name?: string
  code?: string
  address?: string
  city?: string
  province?: string
  postalCode?: string
  country?: string
  phone?: string
  email?: string
  facebook?: string
  twitter?: string
  youtube?: string
  website?: string
  logo?: any[]
  gst?: number
  pst?: number
  hst?: number
  hourlyRate?: number
  onlyInstalledPrice?: number
  initialSetupDone?: boolean
  active?: boolean
  make?: Make
  orderEmailAddresses?: any[]
  orderEmails: string[]
}

export class Dealer implements IDealer {
  id!: number
  dealerGroupId?: number
  name!: string
  code!: string
  address!: string
  city!: string
  province!: string
  postalCode!: string
  country!: string
  phone!: string
  email!: string
  facebook!: string
  twitter!: string
  youtube!: string
  website!: string
  logo!: any[]
  gst!: number
  pst!: number
  hst!: number
  hourlyRate!: number
  onlyInstalledPrice!: number
  initialSetupDone!: boolean
  active!: boolean
  make!: Make
  orderEmailAddresses!: any[]
  orderEmails!: string[]

  constructor (params: IDealer) {
    Object.assign(this, params)
    this.orderEmails = []
    this.orderEmailAddresses.forEach((email: any) => {
      this.orderEmails.push(email.emailAddress)
    })
  }

  public static async getDealers (): Promise<any> {
    const dealers : Dealer[] = []

    const res = await NetworkService.get(process.env.VUE_APP_API_BASE_URL + '/api/v1/auth/dealers')

    res.data.forEach((d: Dealer) => {
      const dealer : Dealer = new Dealer(d)
      dealers.push(dealer)
    })

    return Promise.resolve(dealers)
  }

  public static async getDealer (code: string, makeId: string): Promise<Dealer | null> {
    const res = await NetworkService.get(
      process.env.VUE_APP_API_BASE_URL +
        '/api/v1/dealers/' + makeId + '/' + code
    )
    return new Dealer(res.data.dealer)
  }

  public static async changeStatus(dealerId: number) {
    const res = await NetworkService.post(
      process.env.VUE_APP_API_BASE_URL +
      '/api/v1/auth/dealers/change_status/' + dealerId
    )
  
    return Promise.resolve(res.status)
  }
}
