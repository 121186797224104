<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" app v-if="auth">
      <v-list dense>
        <v-list-item link to="/dealers">
          <v-list-item-action>
            <v-icon>mdi-domain</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Dealers</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/makes">
          <v-list-item-action>
            <v-icon>mdi-car</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Makes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link href="#" @click.prevent="signOut()">
          <v-list-item-action>
            <v-icon>mdi-close</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="indigo" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="auth" />
      <v-toolbar-title>Shop Accessories Admin</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-footer color="indigo" app>
      <span class="white--text">&copy; {{ currentYear }}</span>
    </v-footer>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'App',
  props: {
    source: String
  },
  data: () => ({
    drawer: false
  }),
  computed: {
    ...mapGetters({
      auth: 'auth/isAuth'
    }),
    currentYear () {
      return new Date().getFullYear()
    }
  },
  methods: {
    ...mapActions({
      signOutAction: 'auth/signOut'
    }),
    async signOut () {
      const response = await this.signOutAction()
      if (response === 200) {
        this.$router.push('/login')
      }
    }
  }
}
</script>
