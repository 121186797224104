import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {
  theme: {
    themes: {
      light: {
        primary: '#f54242',
        secondary: '#b0bec5',
        link: '#0abcd4',
        accent: '#0abcd4',
        success: '#7ed321',
        error: '#d5021c'
      }
    }
  }
}

export default new Vuetify(opts)
