import { Product } from '../../models/Product'

const mutations = {
  ADD_PRODUCT (state: any, product: Product) {
    state.list.push(product)
  },
  CLEAR_PRODUCTS (state: any) {
    state.list = []
  },
  SET_SELECTED_PRODUCT (state: any, product: Product) {
    state.selectedProduct = product
  },
  CLEAR_SELECTED_PRODUCT (state: any) {
    state.selectedProduct = null
  },
  SET_LOADING (state: any, loading: boolean) {
    state.loading = loading
  }
}

const getters = {
  selectedProduct (state: any) {
    return state.selectedProduct
  },
  loading (state: any) {
    return state.loading
  }
}

const actions = {
  async loadProducts (context: any, args: any) {
    context.commit('SET_LOADING', true)
    const res = await Product.getProducts(args.make, args.options, args.search)

    res.forEach((product: Product) => {
      context.commit('ADD_PRODUCT', product)
    })

    context.commit('SET_LOADING', false)
  },
  async loadProduct (context: any, productId: number) {
    context.commit('SET_LOADING', true)
    const product: Product | null = await Product.getProduct(productId)
    if (product != null) {
      context.commit('SET_SELECTED_PRODUCT', product)
    }
    context.commit('SET_LOADING', false)
  },

  async reloadProducts (context: any, args: any) {
    context.commit('CLEAR_PRODUCTS')
    context.dispatch('loadProducts', args)
  }
}

const state = () => ({
  list: [] as Product[],
  selectedProduct: null as Product | null,
  loading: false as boolean
})

export default {
  namespaced: true,
  mutations,
  getters,
  state,
  actions
}
