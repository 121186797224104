import { Make } from '../../models/Make'

const mutations = {
  ADD_MAKE (state: any, make: Make) {
    state.list.push(make)
  },
  CLEAR_MAKES (state: any) {
    state.list = []
  },
  SET_CURRENT_MAKE (state: any, make: Make) {
    state.currentMake = make
  },
  CLEAR_CURRENT_MAKE (state: any) {
    state.currentMake = null
  },
  SET_LOADING (state: any, loading: boolean) {
    state.loading = loading
  }
}

const getters = {
  currentMake (state: any) {
    return state.currentMake
  },
  loading (state: any) {
    return state.loading
  }
}

const actions = {
  async loadMakes (context : any, args : any) {
    context.commit('SET_LOADING', true)
    const res = await Make.getMakes()

    res.forEach((make: Make) => {
      context.commit('ADD_MAKE', make)
    })

    context.commit('SET_LOADING', false)
  },
  async loadMake (context : any, makeId : string) {
    context.commit('SET_LOADING', true)
    const make : Make | null = await Make.getMake(makeId)
    if (make != null) {
      context.commit('SET_CURRENT_MAKE', make)
    }
    context.commit('SET_LOADING', false)
  },
  async reloadMakes (context: any, args: any) {
    context.commit('CLEAR_MAKES')
    context.dispatch('loadMakes', args)
  }
}

const state = () => ({
  list: [] as Make[],
  currentMake: Make,
  loading: false as boolean
})

export default {
  namespaced: true,
  mutations,
  getters,
  state,
  actions
}
