import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import dealers from './modules/dealers'
import makes from './modules/makes'
import models from './modules/models'
import products from './modules/products'
import trims from './modules/trims'

import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  getters: {},
  actions: {
  },
  modules: {
    auth: auth,
    dealers: dealers,
    makes: makes,
    models: models,
    products: products,
    trims: trims
  },
  plugins: [
    createPersistedState({
      paths: ['auth']
    })
  ]
})
