import { NetworkService } from '../services/NetworkService'

interface IProduct {
  id : number
  number? : number
  name? : string
  basePrice? : number
  price? : number
  frt? : number
  detail? : string
  spec? : string
  willItFit? : string
  installInstruction? : string
  length? : number
  width? : number
  height? : number
  sizeUnit? : string
  weight? : number
  weightUnit? : string
  featured? : number
  active? : boolean
  images? : any[]
}

export class Product implements IProduct {
  id! : number
  number! : number
  name! : string
  basePrice! : number
  price! : number
  frt! : number
  detail! : string
  spec! : string
  willItFit! : string
  installInstruction! : string
  length! : number
  width! : number
  height! : number
  sizeUnit! : string
  weight! : number
  weightUnit! : string
  featured! : number
  active! : boolean
  images! : any[]

  constructor (params: IProduct) {
    Object.assign(this, params)
  }

  public static async getProducts (make : string, options: any, search: string): Promise<any> {
    const products: Product[] = []

    let order = options.sortDesc[0] === true ? 'desc' : 'asc'
    let sortBy = options.sortBy[0] === undefined ? 'name' : options.sortBy[0]


    const res = await NetworkService.get(
      process.env.VUE_APP_API_BASE_URL +
      '/api/v1/auth/products/' + make +
      '?page=' + options.page + 
      '&rows=' + options.itemsPerPage +
      '&sortBy=' + sortBy + 
      '&order=' + order + 
      '&type=Manufacturer' + 
      '&search=' + search
    )

    res.data.data.forEach((p:any) => {
      const product : Product = new Product(p)
      products.push(product)
    })

    const data = { products: products, total: res.data.meta.total }

    return Promise.resolve(data)
  }

  public static async getProduct (productId: number): Promise<any> {
    const res = await NetworkService.get(
      process.env.VUE_APP_API_BASE_URL +
        '/api/v1/products/' + productId
    )
    return Promise.resolve(new Product(res.data.data))
  }

  public static async uploadProductsCSV(file: any) {
    const res : any = await NetworkService.postCsv(
      process.env.VUE_APP_API_BASE_URL +
      '/api/v1/auth/products/uploadProductsCSV', file
    )

    if (res != -1) {
      const products : Product[] = []

      res.data.data.forEach((p:any) => {
        const product : Product = new Product(p)
        products.push(product)
      })
      return Promise.resolve(products)
    } else {
      return res
    }
  }
}
