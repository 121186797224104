var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"dense":"","disable-sort":"","headers":_vm.headers,"items":_vm.trims,"item-key":"id","group-by":"modelId","items-per-page":50,"search":_vm.search,"loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"group.header",fn:function(ref){
var items = ref.items;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('th',{attrs:{"colspan":"1"}},[_c('v-icon',{on:{"click":toggle}},[_vm._v(_vm._s(isOpen ? 'mdi-minus' : 'mdi-plus'))]),_vm._v(" "+_vm._s(items[0].model.year.year + ' ' + items[0].model.name)+" ")],1),_c('th',[_vm._v(" "+_vm._s(items[0].model.active)+" ")]),_c('th',[_c('v-menu',{attrs:{"offset-y":"","item":items[0].model},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-dots-vertical")])]}}],null,true)},[_c('v-list',[(items[0].model.active == 1)?_c('v-list-item',{attrs:{"dense":""},on:{"click":function($event){return _vm.handleModelClick(items[0].model)}}},[_c('v-list-item-title',[_vm._v("Deactivate Model")]),_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-delete")])],1)],1):_c('v-list-item',{attrs:{"dense":""},on:{"click":function($event){return _vm.handleModelClick(items[0].model)}}},[_c('v-list-item-title',[_vm._v("Activate Model")]),_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check-circle")])],1)],1)],1)],1)],1)]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"400px"},model:{value:(_vm.modelConfirmationDialog),callback:function ($$v) {_vm.modelConfirmationDialog=$$v},expression:"modelConfirmationDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.dialogTitle))]),_c('v-card-text',[_vm._v(_vm._s(_vm.dialogText))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.modelConfirmationDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":_vm.dialogButtonColour,"loading":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.changeModelStatus(_vm.selectedModel)}}},[_vm._v(_vm._s(_vm.dialogButtonText))])],1)],1)],1),_c('v-snackbar',{attrs:{"top":"","right":"","dark":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" "),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v(" Close ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }