import { NetworkService } from '../services/NetworkService'

interface ITrim {
  id: string
  name: string
}

export class Trim implements ITrim {
  id!: string
  name!: string

  constructor (params: ITrim) {
    Object.assign(this, params)
  }

  public static async getTrims (modelId = ''): Promise<any> {
    const res = await NetworkService.get(
      process.env.VUE_APP_API_BASE_URL +
        '/api/v1/auth/trims' +
        (modelId ? '?modelId=' + modelId : '')
    )

    const trims: Trim[] = []
    res.data.forEach((t: any) => {
      const trim: Trim = new Trim(t)
      trims.push(trim)
    })

    return trims
  }

  public static async getTrim (trimId: number): Promise<Trim | null> {
    const res = await NetworkService.get(
      process.env.VUE_APP_API_BASE_URL + '/api/v1/auth/trims/' + trimId
    )
    return new Trim(res.data)
  }

  public static async getTrimsByMake(makeId: string): Promise<Trim[]> {
    let res = await NetworkService.get(
      process.env.VUE_APP_API_BASE_URL +
      '/api/v1/auth/trims/' + makeId
    )

    let trims : Trim[] = []
    res.data.trims.forEach( (t : any) => {
      let trim : Trim = new Trim(t)
      trims.push(trim)
    })

    return Promise.resolve(trims)
   }
}
